// 用户授权
<template>
  <base-dialog
    :visible.sync="visible"
    :title="title"
    @submit="submit"
    class="authorityDialog"
    width="800px"
  >
    <el-row class="flex_between">
      <span>所属部门：{{ department }}</span>
      <base-button label="新增" @click="handleCreate()"></base-button>
    </el-row>
    <el-form :model="formData" ref="userAuthRef">
      <base-table
        :tableAttrs="{
          data: formData.dataSources,
        }"
        ref="userTable"
        :columns="columns"
        :showPage="false"
        :selfChangeHeight="selfChangeHeight"
      >
        <!-- 角色 -->
        <template slot="roleId" slot-scope="{ row, $index }">
          <el-form-item
            :prop="`dataSources[${$index}].roleId`"
            :rules="{
              required: true,
              message: '请选择角色',
              trigger: ['change', 'blur'],
            }"
          >
            <base-select
              :ref="`roleId${$index}`"
              :clearable="true"
              v-model="row.roleId"
              :options="getRoleNameOptions(row)"
              :selectedField="roleNameField"
            ></base-select>
          </el-form-item>
        </template>
        <!-- 数据权限 -->
        <template slot="authorizeType" slot-scope="{ row, $index }">
          <el-form-item
            align="left"
            :prop="`dataSources[${$index}].authorizeType`"
            :rules="[{ validator: authDataRule, trigger: ['blur', 'change'] }]"
          >
            <el-radio-group
              v-model="row.authorizeType"
              :ref="`authorizeType${$index}`"
            >
              <el-radio :label="1">不限</el-radio>
              <el-radio :label="2">部门</el-radio>
              <el-radio :label="3">仅个人</el-radio>
              <el-radio :label="4">自定义</el-radio>
            </el-radio-group>
            <base-button
              style="margin-left: 20px"
              :label="currentLabel(row)"
              type="text"
              v-show="row.authorizeType === 4"
              @click.stop.prevent="handleShowOrg(row, $index)"
            ></base-button>
          </el-form-item>
        </template>
        <!-- 操作列 -->
        <template slot="actions" slot-scope="{ $index }">
          <icon-button
            content="删除"
            @click.stop.prevent="handleDelRow($index)"
            icon="iconfont iconshanchu1"
          ></icon-button>
        </template>
      </base-table>
      <assign-org
        ref="assignOrg"
        :baseOptions="orgOptions"
        @handleValidateChange="handleValidateChange"
      ></assign-org>
    </el-form>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import {
  getDepartmentName,
  getOrgOptions,
  getRolesByUser
} from '@/api/sys/role'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getUserRoles, saveUserRoles } from '@/api/sys/user'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'

import AssignOrg from './components/assign-org.vue'

function findChildByClassName (node, name) {
  let containerBody = null

  const findCurrentNode = (el) => {
    const children = el.children || []
    for (let i = 0; i <= children.length; i++) {
      const child = children[i]
      if (!child) {
        return
      }
      if (child.classList && child.classList.contains(name)) {
        containerBody = child
      } else {
        child.children.length && findCurrentNode(child)
      }
    }
    // children.forEach(child => {
    //   const { children, classList } = child
    //   if (classList.value.indexOf(name) > -1) {
    //     containerBody = child
    //   } else {
    //     children.length && findCurrentNode(child)
    //   }
    // })
  }
  findCurrentNode(node)
  return containerBody
}

export default {
  components: {
    baseDialog,
    BaseTable,
    BaseButton,
    BaseSelect,
    AssignOrg,
    IconButton
  },
  created () {},
  data () {
    return {
      selfChangeHeight: 200,
      visible: false,
      department: '',
      userInfo: {},
      orgEditorIndex: '',
      orgEditorRow: {},
      roleNameOptions: [],
      orgOptions: [],
      roleNameField: ['roleId', 'roleName'],
      formData: {
        dataSources: []
      },
      lastErrorKey: null,
      lastErrorIndex: null
    }
  },
  computed: {
    title () {
      return '授权'
    },
    // 是否是稳城一方
    isAuthData () {
      return this.userInfo.userType === '20'
    },
    columns () {
      return [
        {
          label: '序号',
          type: 'index',
          minWidth: '100px',
          index: (ids) => {
            return ids + 1
          }
        },
        {
          label: '角色名',
          prop: 'roleId',
          minWidth: '150px'
        },
        {
          label: '数据权限',
          prop: 'authorizeType',
          minWidth: '300px',
          // 只有稳城一方角色展示这一项
          isHidden: !this.isAuthData
        },
        {
          label: '操作',
          prop: 'actions',
          minWidth: '100px'
        }
      ]
    }
  },
  methods: {
    initStyle () {
      const html = document.querySelector('html')
      const { zoom } = window.getComputedStyle(html)
      const scale = zoom ? 1 / zoom : 1
      this.selfChangeHeight = 200 * scale
    },
    // 计算表格内容的高度和表格高度的大小
    calculateErrorPos (ids) {
      const lastIndex = ids || this.lastErrorIndex
      const container = this.$refs.userTable.$el
      const containerBody = findChildByClassName(
        container,
        'el-table__body-wrapper'
      )

      if (containerBody && containerBody.children) {
        const table = containerBody.children[0]
        const height = containerBody.clientHeight
        const tableHeight = table.clientHeight
        if (tableHeight > height) {
          // 获取到所有的子元素
          const wrap = findChildByClassName(containerBody, 'el-table__row')
          const childHeight = wrap.children[0].clientHeight
          // 最后一个元素的距离顶部的距离
          const lastErrorHeight = childHeight * (+lastIndex + 1)
          if (lastErrorHeight <= height) {
            containerBody.scrollTop = 0
          } else {
            const instance = lastErrorHeight - height
            containerBody.scrollTop = instance
          }
        }
      }
    },
    // 滚动到最新数据的位置
    scrollNewItemPos () {
      const len = this.formData.dataSources.length
      this.$nextTick(() => {
        this.calculateErrorPos(len)
      })
    },

    handleValidateChange () {
      if (this.orgEditorIndex !== '') {
        this.$nextTick(() => {
          this.$refs.userAuthRef.validateField(
            `dataSources[${this.orgEditorIndex}].authorizeType`
          )
        })
      }
    },

    authDataRule (rule, value, callback) {
      const field = rule.field
      // 取出对应的下标
      const index = field.match(/\[(.*?)\]/)[1]
      const curRow = this.formData.dataSources[index]
      const { authorizeUserInfo = [], authorizeOrgId = [], roleId } = curRow
      if (!roleId) {
        this.lastErrorIndex = index
        this.lastErrorKey = 'roleId'
      }
      if (!value) {
        this.lastErrorIndex = index
        this.lastErrorKey = 'authorizeType'
        callback(new Error('选择数据权限'))
      } else if (
        value &&
        value === 4 &&
        authorizeUserInfo.length === 0 &&
        authorizeOrgId.length === 0
      ) {
        this.lastErrorKey = 'authorizeType'
        this.lastErrorIndex = index
        callback(new Error('未选择自定义数据权限'))
      } else {
        callback()
      }
    },

    handleDelRow (index) {
      this.$confirm('确认删除该条数据？', '提示')
        .then(() => {
          this.formData.dataSources.splice(index, 1)
        })
        .catch(() => {})
    },

    getRoleNameOptions ({ roleId }) {
      const result = []
      // 拿到全部已选择的角色roleIDs
      const roleIds = this.formData.dataSources.map((it) => it.roleId)
      this.roleNameOptions.forEach((it) => {
        const { roleId: id } = it
        if (!roleIds.includes(id) || id === roleId) {
          result.push(it)
        }
      })
      return result
    },

    currentLabel ({ authorizeUserInfo = [], authorizeOrgId = [] }) {
      return authorizeUserInfo.length > 0 || authorizeOrgId.length > 0
        ? '查看'
        : '设置'
    },

    handleShowOrg (row, index) {
      this.orgEditorIndex = index

      this.orgEditorRow = row
      this.$refs.assignOrg.open(row)
    },

    async handleCreate () {
      try {
        // await this.validate()
        this.formData.dataSources.push({})
        this.scrollNewItemPos()
      } catch (error) {
        this.warning('请先完善当前表格输入')
      }
    },

    open (data) {
      this.visible = true
      this.registerClear()
      this.initData(data)
      this.initStyle()
    },

    async initData (data) {
      try {
        this.userInfo = data
        // 角色配置项
        const [
          { data: roles },
          { data: options },
          { data: lists },
          { data: DepartmentObj }
        ] = await Promise.all([
          // 对应用户的角色列表
          getRolesByUser({
            orgType: data.userType
          }),
          // 组织机构相关配置项
          getOrgOptions(),
          // 当前用户的列表数据
          getUserRoles(data.userId, { orgType: data.userType }),
          // 部门名称
          getDepartmentName({ userKid: data.userId })
        ])
        this.roleNameOptions = roles || []
        this.orgOptions = options || []
        this.department = DepartmentObj && DepartmentObj.orgName
        this.formData.dataSources = lists
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * 关闭时处理重置数据逻辑
     */
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        this.department = ''
        this.roleNameOptions = []
        this.orgOptions = []
        this.formData.dataSources = []
        listen()
      })
    },
    // 表单提交校验
    validate () {
      return this.$refs.userAuthRef.validate()
    },

    async submit () {
      try {
        await this.validate()
        // 构建用户数据
        const buildItem = (item) => ({ ...item, userId: this.userInfo.userId })
        let userData = this.formData.dataSources.map(buildItem)
        // 为空的时候处理
        if (userData.length === 0) {
          userData = [{ userId: this.userInfo.userId }]
        }
        const { data: isSuccess } = await saveUserRoles(
          this.isAuthData,
          userData
        )
        if (isSuccess) {
          this.success('保存成功')
          this.$emit('refresh')
          this.visible = false
        } else {
          this.error('保存失败')
        }
      } catch (error) {
        if (error === false) {
          this.warning('请完善表单数据')
          this.calculateErrorPos()
        } else {
          console.error(error)
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.flex_between {
  padding: 10px 0;
  > span {
    flex: 1;
  }
}
.authorityDialog {
  min-width: 1000px;
}
</style>
