<template>
  <base-dialog :visible.sync="visible" title="机构选择" width="1150px" @submit="submit">
    <div class="content">
      <div class="left">
        <el-tree
          :data="baseOptions"
          show-checkbox
          default-expand-all
          node-key="keyId"
          ref="tree"
          @node-click="handleNodeClick"
          highlight-current
          :props="defaultProps"
        >
        </el-tree>
      </div>
      <div class="right">
        <base-pl-table
          :dataSource.sync="tableDatas"
          :api="RoleApi"
          getApi="gteCrmUserList"
          @loadSuccess="loadBack"
          :loadCount.sync="loadCount"
          :queryParas.sync="queryParas"
          ref="userTable"
          :selfChangeHeight="200"
          :webPage="false"
          @selectRowChange="selectRowChange"
          :columns="columns"
        ></base-pl-table>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BasePlTable from '@/components/common/table/base-table/base-table.vue'
import * as RoleApi from '@/api/sys/role'
export default {
  components: { baseDialog, BasePlTable },
  props: {
    baseOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      RoleApi,
      currentKeyId: '',
      // 机构选择的数据
      orgSelectedLists: [],
      // 用户选择的数据
      userSelectedList: {},
      userOrgList: [],
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        orgId: ''
      },
      loadCount: 0,
      visible: false,
      checkedCities: [],
      tableDatas: [],
      editorRow: {},
      selectedRows: [],
      selectedMap: {},
      selectedOrgId: '',
      defaultProps: {
        label: 'orgName',
        children: 'deptList'
      },
      columns: [
        {
          type: 'selection',
          width: '80px'
        },
        {
          label: '人员姓名',
          prop: 'nickName'
        },
        {
          label: '联系电话',
          prop: 'nickName'
        }
      ]
    }
  },
  methods: {

    handleNodeClick ({ keyId }) {
      if (this.queryParas.orgId === keyId) {
        return
      }
      this.queryParas.orgId = keyId

      this.handleFilter()
    },

    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
      this.loading = true
    },
    // 选择数据的切换
    selectRowChange (selections) {
      this.$nextTick(() => {
        const orgId = this.queryParas.orgId
        const currentUserIds = selections.map(it => it.accountId)
        // 对应机构下边的数据进行覆盖
        this.userSelectedList[orgId] = currentUserIds
      })
    },
    /**
     * 请求完成回调
     * 设置表格的默认选择配置
     */
    loadBack (flag) {
      this.$nextTick(() => {
        if (flag && this.queryParas.orgId in this.userSelectedList) {
          const currentOrgUsers = this.userSelectedList[this.queryParas.orgId]
          this.tableDatas.forEach(it => {
            if (currentOrgUsers.includes(it.accountId)) {
              this.$refs.userTable.toggleRowSelection(it)
            }
          })
        }
      })
    },
    submit () {
      const authorizeOrgId = this.$refs.tree.getCheckedKeys()
      // const getCheckedNodes = this.$refs.tree.getCheckedNodes()
      // console.log('authorizeOrgId', authorizeOrgId)
      // console.log('getCheckedNodes', getCheckedNodes)
      const postUserIds = this.buildPostData(this.userSelectedList)
      this.$set(this.editorRow, 'authorizeUserInfo', postUserIds)
      this.$set(this.editorRow, 'authorizeOrgId', authorizeOrgId)
      this.$emit('handleValidateChange')
      this.success('修改成功')
      this.visible = false
    },

    unBuildData (data) {
      const res = {}
      data.forEach(it => {
        const { orgId, userIds } = it
        res[orgId] = userIds
      })
      return res
    },

    buildPostData (data = {}) {
      const res = []
      for (const [key, list] of Object.entries(data)) {
        if (list.length > 0) {
          res.push({
            orgId: key,
            userIds: list
          })
        }
      }
      return res
    },
    open (row) {
      this.editorRow = row
      this.visible = true
      // DONE 组织机构的默认选择
      if (this.editorRow.authorizeOrgId) {
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.editorRow.authorizeOrgId)
        })
      }
      if (this.editorRow.authorizeUserInfo) {
        this.userSelectedList = this.unBuildData(this.editorRow.authorizeUserInfo)
      }
      // 默认查询第一个组织机构
      if (this.baseOptions.length) {
        this.queryParas.orgId = this.baseOptions[0].keyId
        this.$nextTick(() => {
          if (this.queryParas.orgId) {
            this.handleFilter()
          }
        })
      }
      this.registerClear()
    },
    // 添加清楚数据处理
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        listen()
        this.$refs.tree.setCheckedKeys([])
        this.$refs.userTable.clearSelection()
        this.userSelectedList = {}
        this.queryParas.orgId = ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  .left {
    width: 300px;
  }
  .right {
    border-left: 1px solid #ff9900;
    padding-left: 20px;
    flex: 1;
  }
}
</style>
